import { isIp, isOOO } from 'utils/checkInn'
import { DaData } from 'services/dadata'
import { ILegalInformationError } from '../../models'
import { AuthentificationData, ILegalInformation } from './types'
import { CompanyTypes } from './steps/requisitesStep/types'

const formatFullName = ({
  inn,
  firstName,
  lastName,
  middleName,
  name
}: {
  inn?: string
  firstName: string
  lastName: string
  middleName: string
  name?: string
}) => {
  if (isIp(inn)) {
    return `Индивидуальный предприниматель ${lastName} ${firstName} ${middleName}`
  }
  if (isOOO(inn)) {
    return `Общество с ограниченной ответственностью ${name?.replace('ООО', '')}`
  }
  return `${lastName} ${firstName} ${middleName}`
}

export const setDadataValueInForm = (inn: string, setValue: any) => {
  if (inn?.length && [10, 12].includes(inn?.length)) {
    DaData.findById(inn).then((obj: any) =>
      Object.keys(obj).map((key: any) => {
        if (obj[key] && (typeof obj[key] === 'string' ? obj[key].trim() : true)) {
          setValue(key, obj[key])
        }
      })
    )
  }
}

export const setFormError = (
  fieldErrors: Array<ILegalInformationError> | undefined,
  fields: Record<string, string>,
  getValues: any,
  setError: any
) => {
  const errorsForCurrentFields = fieldErrors?.filter((v) => fields[v.field])

  if (errorsForCurrentFields) {
    errorsForCurrentFields.forEach((v) => {
      if (v.rejectedValue === getValues(v.field)) {
        setError(v.field, { message: v.defaultMessage })
      }
    })
  }
}

export const prepareSellerRegisterData = (data: AuthentificationData) => ({
  companyType: data.companyType,
  fullName: formatFullName({
    inn: data.inn,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    name: data.name
  }),
  inn: data.inn,
  kpp: data.kpp,
  ogrn: data.ogrn,
  ceo: {
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    country: 'RUS',
    phone: data.phone,
    birthDate: data.birthDate
  },
  addresses: [
    {
      country: 'RUS',
      street: data.street,
      zip: data.zip,
      city: data?.city?.slice(0, 28).trim(),
      type: data.type ? 'legal' : 'actual'
    }
  ],
  bankAccount: {
    account: data.account,
    bankName: data.bankName,
    bik: data.bik,
    details: 'Оплата товара на сайте kovkert.ru'
  },
  email: data.email,
  shopId: data.shopId,
  name: isIp(data.inn) ? `${data.lastName} ${data.firstName} ${data.middleName}` : data.name
})

export const prepareLegalInformationToForm = (data: ILegalInformation) => ({
  account: data.bankAccount.account,
  bankName: data.bankAccount.bankName,
  bik: data.bankAccount.bik,
  companyType: data.kpp === '000000000' ? CompanyTypes.IP : CompanyTypes.OOO,
  inn: data.inn,
  birthDate: data.ceo.birthDate,
  kpp: data.kpp,
  name: data.name,
  phone: data.ceo.phone,
  ogrn: data.ogrn,
  shopId: '',
  firstName: data.ceo.firstName,
  email: data.email,
  lastName: data.ceo.lastName,
  middleName: data.ceo.middleName,
  country: data.ceo.country,
  city: data.addresses[0].city,
  street: data.addresses[0].street,
  zip: data.addresses[0].zip,
  middleNameRequired: data.ceo.middleName !== 'ОТСТУСТВУЕТ',
  type: data.addresses[0].type === 'legal'
})
