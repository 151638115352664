import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CellContext, ColumnDef, SortingState } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { useMessage, useQueryParams } from 'hooks'
import { routes } from 'constants/routes'
import {
  IProduct,
  IProductError,
  IProductErrorWithProduct,
  IProductImage,
  TProductList,
  TProductsTabCount
} from 'models'
import { setSpaceBetweenCharacters } from 'helpers'
import {
  Button,
  Drawer,
  DropDownList,
  Modal,
  PageEmpty,
  PageHeader,
  PageTabs,
  Pagination,
  Search,
  Table,
  TableSkeleton
} from 'components/UI'
import EditQuantity from 'components/product/EditQuantity/EditQuantity'
import { PreviewCard, ProductErrorsDrawer, ProductImage, SelectedMenu } from 'components/product'
import $api from 'components/http/axios'
import s from './products-page.module.scss'

type TSort =
  | 'name_asc'
  | 'name_desc'
  | 'stock_asc'
  | 'stock_desc'
  | 'price_asc'
  | 'price_desc'
  | 'regprice_asc'
  | 'regprice_desc'

interface ITab {
  name: string
  key: string
  labelKey: string
  labelColor?: 'red' | 'blue'
  disabled?: boolean
}

interface ITabsActions {
  [key: string]: { icon: string; text: string; onClick: ((data?: TProductList) => Promise<void>) | any }[]
}

const tabs = [
  { name: 'В продаже', key: 'on_sale' },
  { name: 'На модерации', key: 'in_moderation' },
  { name: 'Ошибки', key: 'errors', labelColor: 'red' },
  { name: 'На пополнение', key: 'restock', labelColor: 'blue' },
  { name: 'Архив', key: 'archive' }
] as ITab[]

const ProductsPage = () => {
  const { getQueryParams, setQueryParams, searchParams, deleteQueryParams } = useQueryParams()
  const navigate = useNavigate()
  const activeTab = tabs.findIndex((v: ITab) => v.key === getQueryParams('filter')) ?? 0
  const activeTabFilter = getQueryParams('filter')

  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTab)
  const [sorting, setSorting] = useState<SortingState | undefined>(undefined)
  const [selectedRowIds, setSelectedRowIds] = useState<Record<number, boolean>>({})
  const [tabsCount, setTabsCount] = useState<TProductsTabCount>()
  const [previewCard, setPreviewCard] = useState<IProduct | undefined>(undefined)
  const [productErrors, setProductErrors] = useState<IProductErrorWithProduct | undefined>(undefined)
  const [stateModalEditStock, setStateModalEditStock] = useState<{
    view: boolean
    product: undefined | TProductList
  }>({ view: false, product: undefined })
  const { activeShop } = useAppSelector(({ store }) => store.seller)

  const { refetch: refetchTabProductsCount } = useQuery({
    queryKey: ['getProductsCount', activeShop?._id],
    queryFn: async () => {
      const { data } = await $api.get(`/api/seller/${activeShop?._id}/products/count`)
      setTabsCount(data ?? {})
      return data
    }
  })

  const {
    data: products,
    isError,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ['getProducts', activeShop?._id, ...searchParams.entries()],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/seller/${activeShop?._id}/products?${new URLSearchParams(searchParams).toString()}`
      )
      if (status !== 200) return useMessage('Ошибка получения товаров!', 'error')
      setTotalCount(data?.totalCount || 0)
      return data?.products
    },
    retry: 2,
    enabled: Boolean(getQueryParams('filter'))
  })

  const closeModalEditStock = () => {
    setStateModalEditStock({ view: false, product: undefined })
  }

  const clearProductErrors = () => setProductErrors(undefined)

  const getProductById = async (productId: string) => {
    try {
      const response = await $api.get<{ message: IProduct }>(`/api/seller/get_product_card?productId=${productId}`)

      if (response?.data?.message) {
        return response.data.message
      }
    } catch (error) {
      useMessage('Ошибка получения товара!', 'error')
    }
  }

  const handleEditProductStock = async (data?: TProductList) => {
    if (!data) return useMessage('Товар не найден')

    let currentProduct =
      '_id' in data ? data : products.find((v: TProductList) => v._id === Object.keys(selectedRowIds)?.[0])

    if (!currentProduct) {
      currentProduct = await getProductById(Object.keys(selectedRowIds)?.[0])
    }

    if (currentProduct) {
      setStateModalEditStock({
        view: true,
        product: currentProduct
      })
    }
  }

  const handleChangeStatus = async (newStatus: string, data?: TProductList) => {
    try {
      const { status } = await $api.patch(`/api/seller/${activeShop?._id}/products/status`, {
        status: newStatus,
        productIds: data?._id ? [data?._id] : Object.keys(selectedRowIds)
      })

      if (status === 200) {
        await refetch()
        await refetchTabProductsCount()
        setSelectedRowIds({})
      }
    } catch (error) {
      useMessage('Ошибка изменения статуса!', 'error')
    }
  }

  const getProductErrorsById = async (product: TProductList) => {
    try {
      const response = await $api.get<IProductError>(`/api/seller/${activeShop?._id}/products/${product._id}/errors`)

      setProductErrors({ ...response?.data, productData: product })
    } catch (error) {
      useMessage('Ошибка получения товара!', 'error')
    }
  }

  const setProductToPreview = async (productId: string) => {
    const product = await getProductById(productId)
    if (product) setPreviewCard(product)
  }

  const onEditProduct = (data?: TProductList) =>
    navigate(routes.PRODUCTS_EDIT, { state: { productId: data?._id || Object.keys(selectedRowIds)[0] } })

  const onDuplicateProduct = (data?: TProductList) =>
    navigate(routes.PRODUCTS_ADD_NEW, { state: { productId: data?._id || Object.keys(selectedRowIds)[0] } })

  const setFirstPage = () => setQueryParams('page', '1')

  const onPaginationChange = (value: number) => setQueryParams('page', value.toString())

  const onLimitChange = (value: number) => {
    setQueryParams('limit', String(value))
    setFirstPage()
  }

  const onSearchChange = (searchString: string) => {
    if (!searchString) return deleteQueryParams('text')
    setQueryParams('text', searchString)
  }

  const onTabChange = (index: number) => {
    setQueryParams('filter', tabs[index].key)
    setActiveTabIndex(index)
    setSelectedRowIds([])
    if (productErrors) clearProductErrors()
  }

  const onSuccessEditQuantity = async () => {
    await refetch()
    await refetchTabProductsCount()
    setSelectedRowIds({})
  }

  useEffect(() => {
    const sortQueryParams = getQueryParams('sort')

    if (sortQueryParams) {
      if (sortQueryParams === 'name_asc') setSorting([{ id: 'productName', desc: false }])
      if (sortQueryParams === 'name_desc') setSorting([{ id: 'productName', desc: true }])
      if (sortQueryParams === 'stock_asc') setSorting([{ id: 'stock', desc: false }])
      if (sortQueryParams === 'stock_desc') setSorting([{ id: 'stock', desc: true }])
      if (sortQueryParams === 'price_asc') setSorting([{ id: 'discountPrice', desc: false }])
      if (sortQueryParams === 'price_desc') setSorting([{ id: 'discountPrice', desc: true }])
      if (sortQueryParams === 'regprice_asc') setSorting([{ id: 'regularPrice', desc: false }])
      if (sortQueryParams === 'regprice_desc') setSorting([{ id: 'regularPrice', desc: true }])
    }

    if (!activeTabFilter) {
      setQueryParams('filter', tabs[0].key)
      setActiveTabIndex(0)
    }
  }, [])

  useEffect(() => {
    if (sorting) {
      const sortingKeys = {
        productName: ['name_asc', 'name_desc'],
        stock: ['stock_asc', 'stock_desc'],
        discountPrice: ['price_asc', 'price_desc'],
        regularPrice: ['regprice_asc', 'regprice_desc']
      } as Record<string, TSort[]>

      const correctKey = sortingKeys?.[sorting?.[0]?.id]?.[sorting?.[0]?.desc ? 1 : 0]

      if (correctKey) {
        setQueryParams('sort', correctKey)
      } else {
        deleteQueryParams('sort')
      }

      setFirstPage()
    }
  }, [sorting])

  const tabsActions = (key: string) => {
    const initialActions: ITabsActions = {
      on_sale: [
        { icon: 'edit', text: 'Редактировать', onClick: onEditProduct },
        { icon: 'duplicate', text: 'Дублировать', onClick: onDuplicateProduct },
        {
          icon: 'remove',
          text: 'Снять с продажи',
          onClick: (data?: TProductList) => handleChangeStatus('archive', data)
        },
        {
          icon: 'quantity',
          text: 'Изменить количество',
          onClick: handleEditProductStock
        }
      ],
      archive: [
        { icon: 'edit', text: 'Редактировать', onClick: onEditProduct },
        {
          icon: 'remove',
          text: 'Запустить в продажу',
          onClick: (data?: TProductList) => handleChangeStatus('on_sale', data)
        }
      ],
      restock: [
        { icon: 'edit', text: 'Редактировать', onClick: onEditProduct },
        {
          icon: 'remove',
          text: 'Снять с продажи',
          onClick: (data?: TProductList) => handleChangeStatus('archive', data)
        },
        {
          icon: 'quantity',
          text: 'Изменить количество',
          onClick: handleEditProductStock
        }
      ],
      in_moderation: [
        { icon: 'edit', text: 'Редактировать', onClick: onEditProduct },
        { icon: 'duplicate', text: 'Дублировать', onClick: onDuplicateProduct }
      ],
      errors: [
        { icon: 'settings', text: 'Модерировать', onClick: getProductErrorsById },
        { icon: 'edit', text: 'Редактировать', onClick: onEditProduct }
      ]
    }

    if (Object.keys(selectedRowIds)?.length > 1) {
      initialActions.on_sale = [
        {
          icon: 'remove',
          text: 'Снять с продажи',
          onClick: (data?: TProductList) => handleChangeStatus('archive', data)
        }
      ]
      initialActions.archive = [
        {
          icon: 'remove',
          text: 'Запустить в продажу',
          onClick: (data?: TProductList) => handleChangeStatus('on_sale', data)
        }
      ]
      initialActions.errors = []
      initialActions.in_moderation = []
      initialActions.restock = [
        {
          icon: 'remove',
          text: 'Снять с продажи',
          onClick: (data?: TProductList) => handleChangeStatus('archive', data)
        }
      ]
    }

    return initialActions[key] ?? []
  }

  const columns: ColumnDef<TProductList>[] = [
    {
      accessorKey: 'index',
      header: '№',
      size: 65,
      enableSorting: false
    },
    {
      accessorKey: 'images',
      header: 'Фото',
      size: 58,
      minSize: 58,
      enableSorting: false,
      cell: (info: CellContext<TProductList, unknown>) => {
        const images = info.getValue() as IProductImage[]
        return <ProductImage src={images?.[0]?.link} width={26} height={26} className={s['image-cell-wrapper']} />
      }
    },
    {
      accessorKey: 'productName',
      header: 'Наименование',
      minSize: 300
    },
    {
      accessorKey: 'SKU',
      header: 'Артикул',
      size: 200,
      minSize: 200,
      enableSorting: false
    },
    {
      accessorKey: 'discountPrice',
      header: 'Цена, ₽',
      size: 150,
      minSize: 150,
      cell: (info: CellContext<TProductList, unknown>) => setSpaceBetweenCharacters(info.getValue() as number)
    },
    {
      accessorKey: 'regularPrice',
      header: 'Цена до скидки, ₽',
      size: 150,
      minSize: 150,
      cell: (info: CellContext<TProductList, unknown>) => setSpaceBetweenCharacters((info.getValue() as number) || '')
    },
    {
      accessorKey: 'stock',
      header: 'В наличии, шт',
      size: 150,
      minSize: 150
    }
  ]

  if (isError) return <PageEmpty title='Не удалось получить данные. Попробуйте еще раз' />

  return (
    <div className={s.products}>
      <PageHeader
        title='Товары'
        button={<Button onClick={() => navigate(routes.PRODUCTS_ADD)}>Добавить товары</Button>}
        className={s['products__header-wrapper']}
      >
        <div className={s.products__header}>
          <PageTabs>
            {tabs.map((v: ITab, i: number) => (
              <PageTabs.Tab<number>
                key={v.name}
                tabKey={i}
                title={v.name}
                active={activeTabIndex}
                onChange={onTabChange}
                labelColor={v?.labelColor}
                disabled={v?.disabled}
                label={String((tabsCount as any)?.[v?.key] ?? 0)}
              />
            ))}
          </PageTabs>
          <Search onChange={onSearchChange} disabled={!products?.length && !getQueryParams('text')} />
        </div>
      </PageHeader>
      <div className={s.products__content}>
        {isLoading ? (
          <TableSkeleton />
        ) : products?.length ? (
          <Table<TProductList>
            // bodyTrClassName={cx({ [s['table-tr']]: activeTabFilter } )}
            defaultData={products || []}
            defaultColumns={columns}
            onSorting={setSorting}
            onSelect={setSelectedRowIds}
            sorting={sorting || []}
            initialSelectedRows={selectedRowIds}
            onRowClick={(data) =>
              activeTabFilter === 'errors' ? getProductErrorsById(data) : setProductToPreview(data._id)
            }
            rowFeatures={() => tabsActions(activeTabFilter ?? '')}
            manualSelect
            resize
            fluid
          />
        ) : (
          <PageEmpty title='Нет товаров' fullHeight={false} />
        )}
      </div>
      <SelectedMenu
        active={Boolean(Object.keys(selectedRowIds)?.length && tabsActions(activeTabFilter ?? '')?.length)}
        selectedNumber={Object.keys(selectedRowIds)?.length}
        onClose={() => setSelectedRowIds({})}
      >
        {tabsActions(activeTabFilter ?? '').map((i) => (
          <SelectedMenu.Button key={i.text} {...i} />
        ))}
      </SelectedMenu>
      {Boolean(products?.length) && (
        <div className={s.products__footer}>
          <Pagination
            totalCount={totalCount}
            pageIndex={parseInt(getQueryParams('page') ?? '1', 10)}
            onPage={parseInt(getQueryParams('limit') ?? '20', 10)}
            onChange={onPaginationChange}
          />
          <div className={s.limit}>
            <DropDownList<number>
              value={Number(getQueryParams('limit') ?? 20)}
              options={[10, 20, 30, 40, 50]}
              onSelect={onLimitChange}
              label='Количество заказов'
            />
          </div>
        </div>
      )}

      <Modal isOpen={stateModalEditStock.view} onRequestClose={closeModalEditStock}>
        <EditQuantity
          onSuccess={onSuccessEditQuantity}
          product={stateModalEditStock.product}
          onClose={closeModalEditStock}
        />
      </Modal>
      <PreviewCard data={previewCard} isOpen={Boolean(previewCard)} onClose={() => setPreviewCard(undefined)} />
      <Drawer show={Boolean(productErrors)} onClose={clearProductErrors} width='613px' closeOnOutSide={false}>
        <ProductErrorsDrawer data={productErrors} onSuccessEdit={clearProductErrors} />
      </Drawer>
    </div>
  )
}

export default ProductsPage
