import React, { useState } from 'react'
import $api from 'components/http/axios'
import Review from 'components/Review/Review'
import { DotsLoader, PageEmpty, PageHeader, Pagination, Select, Text } from 'components/UI'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'redux/hook'
import { TReview } from 'models/review'
import s from './styles/reviews-page.module.scss'

function ReviewsPage() {
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const [page, setPage] = useState(1)

  const [querySort, setQuerySort] = useState('date_asc')

  const sort = [
    { name: 'По полезности', value: 'populate' },
    { name: 'Новые', value: 'date_asc' },
    { name: 'Старые', value: 'date_desc' },
    { name: 'Лучшие оценки', value: 'value_asc' },
    { name: 'Худшие оценки', value: 'value_desc' }
  ]

  const selectedSorting = sort.find((e) => e.value === querySort)

  const { data, isLoading, isError } = useQuery({
    queryFn: async () => {
      const { data: response, status } = await $api.get(
        `/api/seller/${activeShop?._id}/reviews?page=${page}&limit=${20}&sort=${querySort ?? 'date_asc'}`
      )
      if (status !== 200) return new Error('Не удалось получить отзывы!')
      return response
    },
    queryKey: ['get_reviews', selectedSorting, page]
  })

  if (isError) return <PageEmpty title='Не удалось получить отзывы' />
  if (isLoading) return <DotsLoader center />

  const { reviews, totalCount } = data

  return (
    <div className={s.page_reviews}>
      <PageHeader title='Отзывы' className={s.header}>
        <div className={s.header__sort_wrapper}>
          <Text color='gray'>Сортировать по </Text>
          <Select
            arrowIcon
            className={s.header__sort_select}
            onChange={setQuerySort}
            value={!querySort ? 'Выбрать' : selectedSorting?.name}
            name='Тип'
            placeholder='Тип'
          >
            {sort.map((v) => (
              <Select.Option key={v.value}>{v.name}</Select.Option>
            ))}
          </Select>
        </div>
      </PageHeader>
      <div className={s.page_reviews__reviews}>{reviews?.map((r: TReview) => <Review {...r} />)}</div>
      <div className={s.page_reviews__pagination}>
        <Pagination totalCount={totalCount} pageIndex={page} onPage={20} onChange={setPage} />
      </div>
    </div>
  )
}

export default ReviewsPage
