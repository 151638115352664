import { useQuery } from '@tanstack/react-query'
import $api from 'components/http/axios'
import { useMessage, useQueryParams } from 'hooks'
import { useAppSelector } from 'redux/hook'
import { PartnersTabKeys } from '../consts'

interface IUseGetProgramDetail {
  withdrawalId: string
  programId: string
}

export const useGetProgramDetail = ({ programId, withdrawalId }: IUseGetProgramDetail) => {
  const { activeShop } = useAppSelector(({ store }) => store.seller)
  const { getQueryParams } = useQueryParams()
  const {
    data: programData,
    isError,
    isLoading
  } = useQuery({
    queryKey: ['getProgramDetail'],
    queryFn: async () => {
      const { data, status } = await $api.get(`/api/affiliate/programs/programId/${programId}`)
      if (status !== 200) return useMessage('Ошибка получения деталей партнёрских ссылок программы!', 'error')
      return data || { program: null, partners: [] }
    },
    retry: 2,
    enabled: [PartnersTabKeys.Active, PartnersTabKeys.Archived].includes(getQueryParams('filter') as PartnersTabKeys)
  })

  const { data: paymentData, refetch: refetchDetails } = useQuery({
    queryKey: ['getProgramDetail'],
    queryFn: async () => {
      const { data, status } = await $api.get(
        `/api/affiliate/withdrawals/details/seller/${activeShop?._id}/withdrawalId/${withdrawalId}`
      )
      if (status !== 200) return useMessage('Ошибка получения деталей партнёрских ссылок программы!', 'error')
      return data
    },
    retry: 2,
    enabled: Boolean(getQueryParams('filter') === PartnersTabKeys.Withdrawals)
  })

  const headerData =
    getQueryParams('filter') === PartnersTabKeys.Withdrawals
      ? {
          productName: paymentData?.name,
          productImage: paymentData?.image?.link,
          productId: paymentData?.productId
        }
      : {
          productName: programData?.program?.name,
          productImage: programData?.program?.image?.link,
          productId: programData?.program?.productId
        }

  return {
    headerData,
    program: programData?.program || {},
    partners: programData?.partners || [],
    paymentData,
    isError,
    isLoading,
    refetchDetails
  }
}
